"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserTransport = void 0;
var core_1 = require("@honeybadger-io/core");
var sanitize = core_1.Util.sanitize;
var BrowserTransport = /** @class */ (function () {
    function BrowserTransport() {
    }
    BrowserTransport.prototype.send = function (options, payload) {
        return new Promise(function (resolve, reject) {
            try {
                var x_1 = new XMLHttpRequest();
                x_1.open(options.method, options.endpoint, options.async);
                if (Object.keys(options.headers || []).length) {
                    for (var i in options.headers) {
                        if (typeof options.headers[i] !== 'undefined') {
                            x_1.setRequestHeader(i, String(options.headers[i]));
                        }
                    }
                }
                x_1.send(payload ? JSON.stringify(sanitize(payload, options.maxObjectDepth)) : undefined);
                x_1.onload = function () { return resolve({ statusCode: x_1.status, body: x_1.response }); };
            }
            catch (err) {
                reject(err);
            }
        });
    };
    return BrowserTransport;
}());
exports.BrowserTransport = BrowserTransport;
