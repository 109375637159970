"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStore = void 0;
var GlobalStore = /** @class */ (function () {
    function GlobalStore(store) {
        this.store = store;
    }
    GlobalStore.prototype.getStore = function () {
        return this.store;
    };
    GlobalStore.prototype.run = function (store, callback) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        this.store = store;
        return callback.apply(void 0, args);
    };
    return GlobalStore;
}());
exports.GlobalStore = GlobalStore;
